export enum mousePosition {
  In = 'in',
  Out = 'out',
}

export const swapPhoto = ({
  photos,
  mousePos,
  setDisplayedPhoto,
}: {
  photos?: Sproutl.CloudinaryImageAsset[];
  mousePos: mousePosition;
  setDisplayedPhoto: React.Dispatch<
    React.SetStateAction<Sproutl.CloudinaryImageAsset | null>
  >;
}) => {
  if (!photos) {
    return;
  }

  switch (mousePos) {
    case mousePosition.In:
      if (photos?.[1]) {
        setDisplayedPhoto(photos[1]);
      }
      break;
    case mousePosition.Out:
      setDisplayedPhoto(photos[0]);
      break;
  }
};
