import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import Button from 'components/Button';
import ColorSelector from 'components/ColorSelector';
import { getAttributesLabel } from 'components/global/AttributesLabel/helpers';
import { AttributesLabel } from 'components/global/AttributesLabel/styles';
import ImageComponent from 'components/Image';
import { getProductUrl } from 'utils/commercetools/product';
import formatPrice from 'utils/formatPrice';
import { mobile, tablet } from 'utils/media';

import { swapPhoto, mousePosition } from './helpers';
import * as St from './styles';
import { IProductItemProps } from './types';

export const ProductSkeleton = () => (
  <St.Wrapper displayQuickBuyButton={false}>
    <St.SkeletonResponsiveBox aspectRatio={1} rounded />
    <St.ProductDetails>
      <St.ProductName>
        <St.SkeletonText width={170}>{'\u00A0'}</St.SkeletonText>
      </St.ProductName>
      <St.ProductPrice>
        <St.SkeletonText width={55}>{'\u00A0'}</St.SkeletonText>
      </St.ProductPrice>
    </St.ProductDetails>
  </St.Wrapper>
);

const ProductItem = ({
  product,
  'data-testid': testId,
  displayQuickBuyButton = false,
  toggleQuickBuyModal,
  setQuickBuyModalContent,
}: IProductItemProps) => {
  const {
    name,
    photos,
    shortDescription,
    colors,
    attributeRanges,
    attributesRaw,
  } = product;

  const [displayedPhoto, setDisplayedPhoto] =
    useState<Sproutl.CloudinaryImageAsset | null>(photos?.[0] ?? null);

  const hasPreloadedSecondImage = useRef<boolean>(false);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const attributesLabel = useMemo(
    () => (
      <AttributesLabel stack>
        {getAttributesLabel(attributesRaw)}
      </AttributesLabel>
    ),
    [attributesRaw],
  );

  useEffect(() => {
    // Preload the second image on entrance to the viewport
    // in the same fashion as webP to prevent flicker in loading the image on hover.
    if (photos?.[1] && !hasPreloadedSecondImage.current) {
      new Image().src = photos[1].secure_url;
      hasPreloadedSecondImage.current = true;
    }
  }, [photos, inView, hasPreloadedSecondImage]);

  const productDetails = (
    <St.ProductDetails>
      <St.ProductName>{name}</St.ProductName>
    </St.ProductDetails>
  );

  const priceEl =
    product.fromPrice || product.price ? (
      <St.ProductPrice>
        {product.fromPrice
          ? `From ${formatPrice(product.fromPrice)}`
          : product.price
          ? formatPrice(product.price)
          : null}
      </St.ProductPrice>
    ) : null;

  const colorsEl =
    colors && colors.length > 1 ? (
      <St.ColorWrapper>
        <ColorSelector
          size="small"
          colors={colors}
          limit={{ [mobile]: 3, [tablet]: 5 }}
        />
      </St.ColorWrapper>
    ) : null;

  return (
    <St.Wrapper
      ref={ref}
      displayQuickBuyButton={displayQuickBuyButton}
      data-testid={testId}
      onMouseEnter={() =>
        swapPhoto({ photos, mousePos: mousePosition.In, setDisplayedPhoto })
      }
      onMouseLeave={() =>
        swapPhoto({ photos, mousePos: mousePosition.Out, setDisplayedPhoto })
      }
    >
      <St.StyledSiteLink href={getProductUrl(product)} title={shortDescription}>
        <St.StyledResponsiveBox aspectRatio={1} rounded>
          {displayedPhoto ? (
            <ImageComponent
              loader="cloudinary"
              src={displayedPhoto}
              aspectRatio={1}
              sizes={`${mobile} 150px, 290px`}
              alt={name}
            />
          ) : null}
        </St.StyledResponsiveBox>
        {!displayQuickBuyButton && productDetails}
        {attributeRanges?.length ? (
          <St.VariantDetails>
            {attributeRanges.map((attr, index) => (
              <li key={index}>{attr}</li>
            ))}
          </St.VariantDetails>
        ) : null}
      </St.StyledSiteLink>
      {!displayQuickBuyButton && (
        <div>
          {colorsEl}
          {priceEl}
        </div>
      )}
      {displayQuickBuyButton && (
        <St.VariantPricingAndQuickBuyWrapper displayQuickBuyButton>
          {productDetails}
          {attributesLabel}
          {priceEl}
          <St.QuickBuyWrapper>
            <Button
              variant="tertiaryFilled"
              width="full"
              onClick={() => {
                if (toggleQuickBuyModal && setQuickBuyModalContent) {
                  setQuickBuyModalContent(product);
                  toggleQuickBuyModal(true);
                }
              }}
            >
              Quick Buy
            </Button>
          </St.QuickBuyWrapper>
        </St.VariantPricingAndQuickBuyWrapper>
      )}
    </St.Wrapper>
  );
};

export default ProductItem;
