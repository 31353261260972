import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import { AddedInterface } from 'components/layouts/types';
import { AddToBagProductData } from 'components/product/Layout/types';
import { getActiveCart } from 'utils/commercetools/cart';
import {
  addLineItems,
  getVariantAttributesRawBySku,
} from 'utils/commercetools/cart';
import { trackAddToBasket } from 'utils/gtm/events';

export const useQuickBuyAddToBag = (
  product: Sproutl.ProductVariant | null,
  data: AddToBagProductData | null,
  quantity: number,
) => {
  const router = useRouter();

  const [addedToBag, setAddedToBag] = useState<AddedInterface | null>(null);

  const [isAddingToBag, setIsAddingToBag] = useState<boolean>(false);

  const [addToBagError, setAddToBagError] = useState<string | null>(null);

  const postAddToBag = useCallback(async () => {
    setAddToBagError(null);

    if (product && data && data.bestOffer) {
      setIsAddingToBag(true);

      const lineItems = [
        {
          name: product.name,
          sku: product.sku,
          channel: data.bestOffer.channel,
          quantity,
          price: data.bestOffer.price,
        },
      ];

      try {
        await addLineItems(lineItems);

        trackAddToBasket(lineItems);

        const activeCart = await getActiveCart();
        setAddedToBag({
          updatedAt: new Date(),
          items: [
            {
              title: product.name,
              src: product.photos?.[0],
              price: data.bestOffer.price,
              quantity,
              sku: product.sku,
              attributesRaw: getVariantAttributesRawBySku({
                sku: product.sku,
                lineItems: activeCart.lineItems,
              }),
            },
          ],
        });
      } catch (e) {
        setAddToBagError(e.message);
        setIsAddingToBag(false);
        Sentry.captureException(e);
      }

      setIsAddingToBag(false);
      router.prefetch('/basket');
      router.prefetch('/checkout');
    }
  }, [data, product, quantity, router]);

  return { addedToBag, isAddingToBag, addToBagError, postAddToBag };
};
