import styled, { CSSProp } from 'styled-components';
import { BaseModalBackground } from 'styled-react-modal';

import { AttributesLabel } from 'components/global/AttributesLabel/styles';
import { CarouselItem } from 'components/layout/Carousel';
import { CloseButton } from 'components/ModalNotification/styles';
import { NutshellCol } from 'components/product/Layout/Nutshells/styles';
import { mobile, tablet, desktop } from 'utils/media';
import theme from 'utils/theme';

import {
  Columns,
  IOuickBuySpecialModalBackgroundProps,
  IQuickBuyModalProductInfoProps,
  IAddToBasketWrapperProps,
} from './types';

interface GridProps {
  columns: Columns;
}

export const Grid = styled.div<GridProps>((props) => {
  const baseStyles: CSSProp = {
    display: 'grid',
    gridAutoFlow: 'row dense',
    columnGap: 40,
    rowGap: 50,
  };

  const breakpoints = Object.entries(props.columns).map(
    ([breakpoint, width]) => ({
      [breakpoint]: {
        gridTemplateColumns: `repeat(${width}, 1fr)`,
      },
    }),
  );

  const stylesWithBreakpoints = Object.assign(baseStyles, ...breakpoints);

  stylesWithBreakpoints[mobile] = {
    ...stylesWithBreakpoints[mobile],
    columnGap: 24,
    rowGap: 40,
  };

  return stylesWithBreakpoints;
});

const productInfoPaddingUnits = 24;
const modalBorderRadius = 30;
const modalMobileTopOffset = 45;

export const NutshellsMobileRow = styled.div`
  background-color: ${theme.colors.white};
  margin: 0 -20px;
  position: relative;
  margin-bottom: -1px;
  padding: 20px 0;

  ${NutshellCol} {
    flex: 1 1 auto;
    width: auto;
    max-width: 110px;
  }
`;

export const QuickBuyCarouselWrapper = styled.div`
  ${tablet} {
    min-width: 330px;
    max-width: 498px;
    width: 100%;
    padding: 0 ${productInfoPaddingUnits}px;
    flex: 1 1 50%;
  }
`;

export const QuickBuyModalWrapper = styled.div`
  background-color: ${theme.colors.offWhite};
  border-radius: 30px;
  width: 100vw;
  padding: 18px 0px 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  ${mobile} {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  ${tablet} {
    max-width: 980px;
    width: calc(100vw - 40px);
    border-bottom-right-radius: ${modalBorderRadius}px;
    border-bottom-left-radius: ${modalBorderRadius}px;
    padding-bottom: 20px;
  }

  ${desktop} {
    width: 100vw;
  }

  ${CarouselItem} {
    padding: 0;
  }

  ${CloseButton} svg {
    pointer-events: none;
  }
`;

export const OuickBuySpecialModalBackground = styled(
  BaseModalBackground,
)<IOuickBuySpecialModalBackgroundProps>`
  position: fixed;
  background-color: #00000050;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 997; // just below cookie notice
  z-index: 998;

  > .modal-wrapper {
    ${mobile} {
      height: 100%;
      padding-top: 48px;
    }

    ${tablet} {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  ${mobile} {
    opacity: 1;

    ${QuickBuyModalWrapper} {
      position: absolute;
      left: 0;
      transition: all 400ms ease-in-out;
      // Leverage the pre-existing opacity prop for slider effect on mobile ...
      top: ${(props) => `${props.opacity ? '6%' : '100%'};`};
    }
  }

  ${tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 400ms ease-in-out;
    opacity: ${(props) => props.opacity};
    padding: 0 15px;
  }
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 15px;

  ${mobile} {
    position: absolute;
    right: 0;
  }
`;

export const MobileModalSubHeading = styled.p`
  display: block;

  ${tablet} {
    display: none;
  }
`;

export const DesktopModalSubHeading = styled.p`
  display: none;

  ${tablet} {
    display: block;
  }
`;

export const QuickBuyModalProductInfo = styled.div<IQuickBuyModalProductInfoProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 ${productInfoPaddingUnits}px;
  margin: 23px 0 0;

  ${mobile} {
    margin-bottom: ${(props) =>
      !props.hasCookieAccepted
        ? `calc(210px + ${modalMobileTopOffset}px)`
        : `calc(88px + ${modalMobileTopOffset}px)`};
  }

  > *,
  > ${AttributesLabel} {
    margin: 0 0 12px;
  }

  ${NutshellsMobileRow} {
    align-self: stretch;
    margin: 0 -${productInfoPaddingUnits}px 12px;

    ${tablet} {
      order: 6;
      background: transparent;
      margin: 0;
    }
  }

  > button {
    display: none;

    ${tablet} {
      order: 7;
      display: block;
      margin-top: auto;
      margin-bottom: 30px;
    }
  }

  ${tablet} {
    flex: 1 1 50%;
    margin-top: 0;
  }

  ${desktop} {
    h2 {
      font-size: 32px;
    }
  }
`;

export const ModalBody = styled.div`
  ${mobile} {
    overflow-y: scroll;
    margin-top: ${modalMobileTopOffset}px;
  }

  ${tablet} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const AddToBasketWrapper = styled.div<IAddToBasketWrapperProps>`
  display: flex;
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px 20px ${(props) => (!props.hasCookieAccepted ? '145px' : '20px')};
  background: ${theme.colors.white};
  z-index: ${theme.zIndexes.mid};
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);

  ${tablet} {
    display: none;
  }
`;
