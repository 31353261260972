import React, { useMemo, useState } from 'react';
import { ModalProvider } from 'styled-react-modal';

import { useCookieAccepted } from 'components/global/CookieBanner/utils';
import { Close } from 'components/icons';
import ModalNotification from 'components/ModalNotification';
import { CloseButton } from 'components/ModalNotification/styles';
import AddToNotification from 'components/product/AddToNotification';
import ProductItem, { ProductSkeleton } from 'components/ProductItem';
import { mobile, tablet } from 'utils/media';

import { getModalBody } from './helpers';
import { useQuickBuyAddToBag } from './hooks';
import {
  Grid,
  QuickBuyModalWrapper,
  CloseButtonWrapper,
  ModalBody,
  OuickBuySpecialModalBackground,
} from './styles';
import { IProductsData } from './types';

const defaultColumns = {
  [mobile]: 2,
  [tablet]: 3,
};

const ProductGrid = ({
  products = [],
  skeleton,
  columns = defaultColumns,
  skeletonOutOfStockProducts = false,
  isQuickBuyEnabled = false,
}: IProductsData) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalContent, setModalContent] =
    useState<Sproutl.ProductListing | null>(null);

  const { postAddToBag, isAddingToBag, addedToBag } = useQuickBuyAddToBag(
    modalContent,
    { bestOffer: modalContent?.bestOffer || null },
    1,
  );

  const hasCookieAccepted = useCookieAccepted();

  const modalBodyArgs = useMemo(
    () => ({
      product: modalContent || undefined,
      setIsModalOpen,
      postAddToBag,
      isAddingToBag,
      hasCookieAccepted,
    }),
    [
      modalContent,
      setIsModalOpen,
      postAddToBag,
      isAddingToBag,
      hasCookieAccepted,
    ],
  );

  return (
    <>
      <Grid columns={columns}>
        {products.map((product: any, index) =>
          skeleton || (!product.isOnStock && skeletonOutOfStockProducts) ? (
            <ProductSkeleton key={`${product.sku}-${index}`} />
          ) : (
            <ProductItem
              key={`${product.sku}-${index}`}
              data-testid="productGridItem"
              product={product}
              displayQuickBuyButton={isQuickBuyEnabled}
              {...(isQuickBuyEnabled && {
                toggleQuickBuyModal: setIsModalOpen,
                setQuickBuyModalContent: setModalContent,
              })}
            />
          ),
        )}
      </Grid>
      {isQuickBuyEnabled && (
        <ModalProvider backgroundComponent={OuickBuySpecialModalBackground}>
          <ModalNotification
            isOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            isBespokeModal
          >
            <QuickBuyModalWrapper>
              <CloseButtonWrapper>
                <CloseButton
                  id="btn-quick-buy-close"
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                >
                  <Close height={16} width={16} />
                </CloseButton>
              </CloseButtonWrapper>
              <ModalBody>{getModalBody(modalBodyArgs)}</ModalBody>
            </QuickBuyModalWrapper>
          </ModalNotification>
        </ModalProvider>
      )}
      {addedToBag ? (
        <AddToNotification
          isOpen
          productDetails={addedToBag.items}
          key={addedToBag.updatedAt.toString()}
        />
      ) : null}
    </>
  );
};

export default ProductGrid;
